import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthService from "../auth/AuthService";

import { FaCartArrowDown } from "react-icons/fa";
import Footer from "./Footer";
import HomeSlidePicturesList from "./HomeSlidePicturesList";
import OurProduct from "./OurProduct";

import "../css/Home.css";

function Home() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({ items: [], totalPrice: 0 });
  const navigate = useNavigate();

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app";
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/admin/products/public/display`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    const localCart = JSON.parse(localStorage.getItem("cart")) || {
      items: [],
      totalPrice: 0,
    };
    setCart(localCart);

    fetchProducts();
  }, []);

  const addToCart = async (product) => {
    const user = AuthService.getCurrentUser();
    if (user) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/cart/add`,
          { productId: product._id, quantity: 1 },
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        setCart(response.data);
      } catch (error) {
        console.error(
          "Error adding item to cart:",
          error.response?.data || error
        );
      }
    } else {
      let storedCart = JSON.parse(localStorage.getItem("cart")) || {
        items: [],
        totalPrice: 0,
      };
      const productIndex = storedCart.items.findIndex(
        (item) => item.productId === product._id
      );
      if (productIndex !== -1) {
        storedCart.items[productIndex].quantity += 1;
      } else {
        storedCart.items.push({
          productId: product._id,
          name: product.name,
          price: product.price,
          quantity: 1,
        });
      }
      storedCart.totalPrice = storedCart.items.reduce(
        (total, currentItem) =>
          total + currentItem.price * currentItem.quantity,
        0
      );
      setCart(storedCart);
      localStorage.setItem("cart", JSON.stringify(storedCart));
    }
  };

  const handleProceed = () => {
    navigate("/login");
  };

  return (
    <div className="home-container__trio">
      <div className="header-container__trio">
        <header className="brand-area__trio">
          <Link to="/home" className="brand-link__trio">
            <h1 className="brand-title__trio">
              <span className="brand-highlight__trio">TrioTech</span> Commercial
            </h1>
          </Link>
          <div className="login_signup_container">
            <Link to="/login">
              <button className="signup__login-button">Log In</button>
            </Link>
            <Link to="/signup">
              <button className="login__signup-button">Signup</button>
            </Link>
          </div>
          <div className="cart__items_container">
            <button onClick={handleProceed}>
              <FaCartArrowDown />
              {cart.items.reduce((total, item) => total + item.quantity, 0)}
            </button>
            <p>Total: ${cart.totalPrice.toFixed(2)}</p>
          </div>
        </header>
      </div>

      <div>
        <HomeSlidePicturesList />
      </div>
      <hr />

      <OurProduct products={products} addToCart={addToCart} />

      <Footer />
    </div>
  );
}

export default Home;

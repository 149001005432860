import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService"; 
import "../css/Departments.css"; 
import { Link } from "react-router-dom";

const API_BASE_URL =
  "https://trio-teach-backend-ecom.vercel.app/admin/departments/";

const getAuthHeader = () => {
  const user = AuthService.getCurrentUser();
  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` };
  } else {
    console.error("No token found or user not logged in");
    return {};
  }
};

// Get all departments - Public
/*
router.get('/public/all', async (req, res) => {
  try {
    const departments = await Department.find();
    res.json(departments);
  } catch (error) {
    res.status(500).send(error.message);
  }
});
*/

const fetchAllDepartments = () => {
  return axios.get(`${API_BASE_URL}public/all`, {
    headers: getAuthHeader(),
  });
};

function DepartmentsList() {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchAllDepartments()
      .then((response) => {
        setDepartments(response.data);
      })
      .catch((error) => {
        console.error(
          "Failed to fetch departments:",
          error.response ? error.response.data : error
        );
      });
  }, []);

  return (
    <div className="department-container" id="departmentContainer">
      <header className="brand-area__trio">
        <Link to="/home" className="brand-link__trio">
          <h1 className="brand-title__trio">
            <span className="brand-highlight__trio">TrioTech</span> Commercial
          </h1>
        </Link>
      </header>
      <h2 className="department-heading" id="departmentListHeading">
        Department List
      </h2>
      <ul className="department-list" id="departmentList">
        {departments.map((department) => (
          <li
            key={department._id}
            className="department-item"
            id={`departmentItem-${department._id}`}
          >
            <b>{department.name}</b>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DepartmentsList;

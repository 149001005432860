import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/OrderConfirmation.css";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, orderDate, customerName } = location.state || {}; // Assuming these values are passed
  const handleReturn = () => {
    navigate("/user/dashboard");
  };

  return (
    <div className="mainOrderConfirmation">
      <div className="orderConfirmation">
        <h1>Order Confirmation</h1>
        {orderId ? (
          <>
            <p className="success">Your order has been placed successfully.</p>
            <div className="orderDetails">
              <p>
                <strong>Order ID:</strong> {orderId}
              </p>
            </div>
          </>
        ) : (
          <p className="error">Order information is missing.</p>
        )}
               <button className="returnButton" onClick={handleReturn}>Return to Dashboard</button>
      </div>
    </div>
  );
};

export default OrderConfirmation;

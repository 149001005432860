import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/SlidePictures.css";

function SlidePicturesList() {
  const [slidePictures, setSlidePictures] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchSlidePictures = async () => {
      try {
        const response = await axios.get(
          "https://trio-teach-backend-ecom.vercel.app/admin/slidePicturesList"
        );
        setSlidePictures(response.data.flatMap((slide) => slide.images));
      } catch (error) {
        console.error("Failed to fetch slide pictures:", error.response);
      }
    };

    fetchSlidePictures();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % slidePictures.length);
    }, 7500);

    return () => clearInterval(interval);
  }, [currentIndex, slidePictures.length]);

  const previousSlide = () => {
    setCurrentIndex(
      (currentIndex - 1 + slidePictures.length) % slidePictures.length
    );
  };

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % slidePictures.length);
  };

  return (
    <div className="custom-slide-picture-container">
      <div className="custom-slide-show">
        <button className="custom-slide-button prev" onClick={previousSlide}>
          &lt;
        </button>
        {slidePictures.length > 0 && (
          <div className="custom-image-container">
            <img
              src={slidePictures[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="custom-fade-in-image"
            />
          </div>
        )}
        <button className="custom-slide-button next" onClick={nextSlide}>
          &gt;
        </button>
      </div>
    </div>
  );
}

export default SlidePicturesList;

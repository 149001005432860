import React from "react";
import "../css/OurProduct.css";

function OurProduct({ products, addToCart }) {
  return (
    <div className="best-deal__trio">
      <h2 className="products-heading__trio">Discover Our Top Products</h2>
      <div className="products-list__trio">
        {products.map((product) => (
          <div key={product._id} className="product-item__trio">
            <img
              src={product.images && product.images[0]}
              alt={product.name}
              className="product-image__trio"
            />
            <div className="product-details__trio">
              <h3 className="product-name__trio">{product.name}</h3>
              <p className="product-price__trio">
                Price: <strong>${product.price}</strong>
              </p>
              <p className="product-brand__trio">
                Brand: <em>{product.brand}</em>
              </p>
              <div className="product-actions__trio">
                <button
                  onClick={() => addToCart(product)}
                  className="add-to-cart-button__trio"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurProduct;

// ShoppingInfo.js

import React from 'react';

function ShoppingInfo() {
  return (
    <div>
      <h2>Shopping Information</h2>
      {/* Your shopping information content goes here */}
    </div>
  );
}

export default ShoppingInfo;

import React, { useState } from "react";
import Sidebar from "./Sidebar";
import DashboardContent from "./DashboardContent";
import "../Admiin/css/adminDashboard.css";

function AdminDashboard2() {
  const [activeMenu, setActiveMenu] = useState("dashboard");

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  return (
    <div className="layout-container">
      <div id="sidebarSidebar">
        <Sidebar handleMenuClick={handleMenuClick} />
      </div>
      <div className="main-content">
        <DashboardContent activeMenu={activeMenu} />
      </div>
    </div>
  );
}

export default AdminDashboard2;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/Login.css";
import Footer from "./Footer";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const mergeCarts = async (token) => {
    const guestCart = JSON.parse(localStorage.getItem("cart")) || {
      items: [],
      totalPrice: 0,
    };
    if (guestCart.items.length > 0) {
      try {
        await axios.post("/api/cart/merge", guestCart, {
          headers: { Authorization: `Bearer ${token}` },
        });
        localStorage.removeItem("cart");
      } catch (error) {
        console.error("Error merging carts:", error.response?.data || error);
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await AuthService.login(email, password);
      await mergeCarts(user.token);
      if (user.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/user/dashboard");
      }
    } catch (error) {
      console.error("Login error:", error.response?.data || error);
    }
  };

  return (
    <div className="login__container_all">
      <header className="login__header">
        <Link to="/home" className="login__header-link">
          <h1 className="login__title">
            <span>TrioTech</span> Commercial
          </h1>
        </Link>
      </header>
      <div className="login">
        <div className="login__background">
          <div className="login__container">
            <div className="login__card">
              <h2 className="login__heading">Log In</h2>
              <form onSubmit={handleLogin} className="login__form">
                <label htmlFor="email" className="login__label">
                  Email:
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="login__input"
                  placeholder="Email"
                  required
                />
                <label htmlFor="password" className="login__label">
                  Password:
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="login__input"
                  placeholder="Password"
                  required
                />
                <button type="submit" className="login__submit">
                  Login
                </button>
                <div className="login__signup-link">
                  <p className="login__signup-text">Don't have an account?</p>
                  <Link to="/signup">
                    <button className="login__signup-button">Signup</button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__container">
        <Footer />
      </div>
    </div>
  );
}

export default Login;

import React from "react";
import Departments from "./../Departments";
import Category from "./../Category";
import Attributes from "./../Attributes";
import Product from "./../Product";
import Orders from "./../Orders";
import AdminInventory from "./../AdminInventory";
import FetchShippingAdmin from "./../FetchShippingAdmin";
import Tracker from "./../Tracker";
import UsersContent from "../Admiin/UsersContent";

import "../Admiin/css/dashboardcontent.css";
import AdminBarComponent from "./AdminBarComponent";
import AddSlidePictures from "../AddSlidePictures";
import AddHomeSlidePictures from "../AddHomeSlidePictures";

import AddProductAndUpdateDetails from "../AddProductAndUpdateDetails";
import AddBestProduct from "../bestProduct/AddBestProduct.jsx";

function DashboardContent({ activeMenu }) {
  return (
    <div className="main-content">
      {activeMenu === "dashboard" && (
        <div className="admin_dashboard_info">
          <h1>Dashboard</h1>
          <div className="bar_graph_component">
          <AdminBarComponent/>
        </div>
        </div>
      )}
      {activeMenu === "departments" && <Departments />}
      {activeMenu === "categories" && <Category />}
      {activeMenu === "attributes" && <Attributes />}
      {activeMenu === "products" && <Product />}
      
      {activeMenu === "orders" && <Orders />}
      {activeMenu === "inventory" && <AdminInventory />}
      {activeMenu === "shippings" && <FetchShippingAdmin />}
      {activeMenu === "tracker" && <Tracker />}
      {activeMenu === "users" && <UsersContent />}

      {/* Addmin Part will be deleted */}
      {activeMenu === "slidePictures" && <AddSlidePictures />}
      {activeMenu === "homeSlidePictures" && <AddHomeSlidePictures />}
      {activeMenu === "productDetails" && <AddProductAndUpdateDetails />}
      {activeMenu === "addBestProduct" && <AddBestProduct />}
      
    </div>
  );
}

export default DashboardContent;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import AuthService from "../auth/AuthService"; // Ensure this is correctly importedimport { Link } from 'react-router-dom';

import Header from "./Header";
import { FaCartPlus } from "react-icons/fa";
import { ImUserPlus } from "react-icons/im";
import BestProductList from "./bestProduct/BestProductList";
import { TiInfo } from "react-icons/ti";
import SlidePicturesList from "./SlidePicturesList";
import Footer from "./Footer";

import "../css/UserDashboard.css";

function UserDashboard() {
  const [products, setProducts] = useState([]);

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app";

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/admin/products/public/display`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, []);

  const addToCart = async (productId) => {
    try {
      const user = AuthService.getCurrentUser();
      if (!user) {
        alert("Please log in to add items to the cart");
        return;
      }

      await axios.post(
        `${API_BASE_URL}/api/cart/add`,
        {
          productId,
          quantity: 1,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      alert("Product added to cart!");
    } catch (error) {
      console.error("Error adding product to cart:", error);
      alert("Failed to add product to cart.");
    }
  };

  const addToFavorites = async (productId) => {
    try {
      const user = AuthService.getCurrentUser();
      if (!user) {
        alert("Please log in to add items to favorites");
        return;
      }

      await axios.post(
        `${API_BASE_URL}/api/favorites/add`,
        {
          productId,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      alert("Product added to favorites!");
    } catch (error) {
      console.error("Error adding product to favorites:", error);
      alert("Failed to add product to favorites.");
    }
  };

  return (
    <div id="user-dashboard">
      <Header />
      <br/>
      <SlidePicturesList />
      <hr />
      <h1 className="best__deal">The Best Deal</h1>
      <BestProductList />
      <h1 className="our__products">Our Products</h1>

      <div id="unique-products-list">
        {products.map((product, index) => (
          <div
            key={index}
            className="unique-product-item"
            id={`unique-product-item-${index}`}
          >
            <h3 className="product-name">{product.name}</h3>
            <p className="product--price">Price: ${product.price}</p>
            <p className="product--brand">Brand: {product.brand}</p>
            <p className="product--brand">Stock: {product.productQuantity}</p>

            {product.images && product.images[0] && (
              <img
                src={product.images[0]}
                alt={product.name}
                className="product-image"
              />
            )}
            <button
              onClick={() => addToCart(product._id)}
              className="add-to-cart-btn"
            >
              <FaCartPlus />
            </button>
            <button
              onClick={() => addToFavorites(product._id)}
              className="add-to-favorites-btn"
            >
              <ImUserPlus />
            </button>

            <Link
              to={`/products/${product._id}`}
              className="product-details-link"
            >
              Details <TiInfo />
            </Link>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default UserDashboard;

import React, { useState } from "react";
import axios from "axios";
import "../../components/bestProduct/AddBestProduct.css";

const AddBestProduct = () => {
  const [product, setProduct] = useState({
    productName: "",
    productDescription: "",
    originalPrice: "",
    discountPrice: "",
    imageUrl: [],
    categories: [],
    dealValidUntil: "",
  });

  const handleImageChange = (e, index) => {
    let imgs = [...product.imageUrl];
    imgs[index] = e.target.value;
    setProduct({ ...product, imageUrl: imgs });
  };

  const handleCategoryChange = (e, index) => {
    let cats = [...product.categories];
    cats[index] = e.target.value;
    setProduct({ ...product, categories: cats });
  };

  const addImageField = () => {
    setProduct({ ...product, imageUrl: [...product.imageUrl, ""] });
  };

  const addCategoryField = () => {
    setProduct({ ...product, categories: [...product.categories, ""] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        "https://trio-teach-backend-ecom.vercel.app/product/add-best-deal",
        product
      );
      alert("Product added successfully!");
      setProduct({
        productName: "",
        productDescription: "",
        originalPrice: "",
        discountPrice: "",
        imageUrl: [],
        categories: [],
        dealValidUntil: "",
      });
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Failed to add product");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="add-best-product">
      <h2 className="form-title">Add Best Product Deal</h2>
      <form onSubmit={handleSubmit} className="product-form">
        <div className="form-group">
          <label className="form-label_best_product_best_product">Product Name</label>
          <input
            className="form-input"
            name="productName"
            value={product.productName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label_best_product">Product Description</label>
          <textarea
            className="form-textarea"
            name="productDescription"
            value={product.productDescription}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label className="form-label_best_product">Original Price</label>
          <input
            className="form-input"
            type="number"
            name="originalPrice"
            value={product.originalPrice}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label_best_product">Discount Price</label>
          <input
            className="form-input"
            type="number"
            name="discountPrice"
            value={product.discountPrice}
            onChange={handleChange}
            required
          />
        </div>

        {product.imageUrl.map((img, index) => (
          <div className="form-group" key={index}>
            <label className="form-label_best_product">Image URL {index + 1}</label>
            <input
              className="form-input"
              type="text"
              value={img}
              onChange={(e) => handleImageChange(e, index)}
            />
          </div>
        ))}
        <button
          type="button"
          className="form-button add-image"
          onClick={addImageField}
        >
          Add Another Image
        </button>

        {product.categories.map((category, index) => (
          <div className="form-group" key={index}>
            <label className="form-label_best_product">Category {index + 1}</label>
            <input
              className="form-input"
              type="text"
              value={category}
              onChange={(e) => handleCategoryChange(e, index)}
            />
          </div>
        ))}
        <button
          type="button"
          className="form-button add-category"
          onClick={addCategoryField}
        >
          Add Another Category
        </button>

        <div className="form-group">
          <label className="form-label_best_product">Deal Valid Until</label>
          <input
            className="form-input"
            type="date"
            name="dealValidUntil"
            value={product.dealValidUntil}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="form-button submit-button">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddBestProduct;

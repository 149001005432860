import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/PublicTrackingInfo.css";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function PublicTrackingInfo() {
  const [trackingInfo, setTrackingInfo] = useState([]);
  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = AuthService.getCurrentUser();
        if (!user) {
          console.log("User not logged in");
          return;
        }

        const headers = {
          Authorization: `Bearer ${user.token}`,
        };
        const response = await axios.get(`${API_BASE_URL}/tracking`, {
          headers,
        });
        setTrackingInfo(response.data);
      } catch (error) {
        console.error("Error fetching tracking info:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="tracking-info-container">
        <h3 className="tracking-info-heading">Public Tracking Information</h3>
        <header className="brand-area__trio">
          <Link to="/home" className="brand-link__trio">
            <h1 className="brand-title__trio">
              <span className="brand-highlight__trio">TrioTech</span> Commercial
            </h1>
          </Link>
        </header>
        <table className="tracking-info-table">
          <thead className="tracking-info-thead">
            <tr className="tracking-info-header-row">
              <th className="tracking-info-header">Order ID</th>
              <th className="tracking-info-header">Shipping ID</th>
              <th className="tracking-info-header">Comments</th>
              <th className="tracking-info-header">Status</th>
              <th className="tracking-info-header">Shipping Company Name</th>
              <th className="tracking-info-header">Shipping Tracker ID</th>
            </tr>
          </thead>
          <tbody className="tracking-info-tbody">
            {trackingInfo.map((info, index) => (
              <tr key={index} className="tracking-info-row">
                <td className="tracking-info-cell">{info.orderId}</td>
                <td className="tracking-info-cell">{info.shippingId}</td>
                <td className="tracking-info-cell">{info.comments}</td>
                <td className="tracking-info-cell">{info.status}</td>
                <td className="tracking-info-cell">
                  {info.shippingCompanyName}
                </td>
                <td className="tracking-info-cell">{info.shippingTrackerId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer__container">
        <Footer />
      </div>
    </>
  );
}

export default PublicTrackingInfo;

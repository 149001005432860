import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../../auth/AuthService";

import "../Admiin/css/usercontent.css";

function UsersContent() {
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [editDetails, setEditDetails] = useState({ username: "", email: "" });

  useEffect(() => {
    fetchUsers();
  }, []);

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app/admin";

  const fetchUsers = async () => {
    try {
      const { token } = AuthService.getCurrentUser();
      const response = await axios.get(`${API_BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUserById = async (id) => {
    try {
      const { token } = AuthService.getCurrentUser();
      const response = await axios.get(
        `${API_BASE_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserDetails(response.data);
      setEditDetails({
        username: response.data.username,
        email: response.data.email,
      });
    } catch (error) {
      console.error("Error fetching user by ID:", error);
    }
  };

  const deleteUserById = async (id) => {
    try {
      const { token } = AuthService.getCurrentUser();
      await axios.delete(`${API_BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUsers(); // Refresh the user list after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const updateUserById = async (id) => {
    try {
      const { token } = AuthService.getCurrentUser();
      await axios.put(`${API_BASE_URL}/users/${id}`, editDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUsers(); // Refresh the user list after update
      setUserDetails(null); // Clear the current user details
      setEditDetails({ username: "", email: "" }); // Reset edit form
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (userDetails) {
      await updateUserById(userDetails._id);
    }
  };
  

  return (
    <div>
      <div className="users-content">
              <div>
                <h2>All Users</h2>
                {userDetails && (
                  <form onSubmit={handleUpdateSubmit} className="user-form">
                    <label className="form-label">Username:</label>
                    <input
                      type="text"
                      name="username"
                      value={editDetails.username}
                      onChange={handleEditChange}
                      className="text-input"
                    />
                    <label className="form-label">Email:</label>
                    <input
                      type="email"
                      name="email"
                      value={editDetails.email}
                      onChange={handleEditChange}
                      className="text-input"
                    />
                    <button type="submit" className="action-btn">Save Changes</button>
                  </form>
                )}
                {users.length > 0 ? (
                  <div className="table-container">
                    <table className="user-table">
                      <thead>
                        <tr>
                          <th className="table-header">#</th>
                          <th className="table-header">Username</th>
                          <th className="table-header">Email</th>
                          <th className="table-header">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={user._id}>
                            <td>{index + 1}</td>
                            <td className="username">{user.username}</td>
                            <td>{user.email}</td>
                            <td>
                              <button onClick={() => fetchUserById(user._id)} className="edit-btn">Edit</button>
                              <button onClick={() => deleteUserById(user._id)} className="delete-btn">Delete</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No users found.</p>
                )}
              </div>
          </div>
    </div>
  );
}

export default UsersContent;

import React from "react";
import "../css/Footer.css"; // Make sure the path to your CSS file is correct

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h3>TrioTech</h3>
        <p>
          Building the future of e-commerce with the latest in web technologies.
        </p>
        <div className="footer-tech-stack">
          <span>Node.js</span>
          <span>MongoDB</span>
          <span>JWT</span>
          <span>Express</span>
          <br/>
          <span>React</span>
          <span>StripeJs</span>
          <span>Bootstrap</span>
        </div>
        <div className="footer-company">
          © 2024 TrioTech, All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

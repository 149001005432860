import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthService from "../auth/AuthService";
import { CiSearch } from "react-icons/ci";
import { MdOutlineFavorite } from "react-icons/md";
import { FaCartArrowDown, FaHistory, FaFedex } from "react-icons/fa";
import "../css/Header.css";
import { debounce } from "lodash";

const DEPARTMENTS_API_URL =
  "https://trio-teach-backend-ecom.vercel.app/admin/departments/public/all";
const CATEGORIES_API_URL =
  "https://trio-teach-backend-ecom.vercel.app/admin/categories/public/all";

const Header = () => {
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [noProductsFound, setNoProductsFound] = useState(false);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const departmentsResponse = await axios.get(DEPARTMENTS_API_URL, {
        headers: getAuthHeader(),
      });
      setDepartments(departmentsResponse.data);

      const categoriesResponse = await axios.get(CATEGORIES_API_URL, {
        headers: getAuthHeader(),
      });
      setCategories(categoriesResponse.data);
    };
    fetchData();
  }, []);

  const getAuthHeader = () => {
    const user = AuthService.getCurrentUser();
    return user && user.token ? { Authorization: `Bearer ${user.token}` } : {};
  };

  const fetchProducts = async (categoryId) => {
    setActiveCategory(categoryId);
    setIsFetchingProducts(true);
    setNoProductsFound(false);

    try {
      const response = await axios.get(
        `https://trio-teach-backend-ecom.vercel.app/admin/products/by-category/${categoryId}`,
        { headers: getAuthHeader() }
      );
      setProducts(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNoProductsFound(true);
        setProducts([]);
      } else {
        console.error("An error occurred while fetching products:", error);
      }
    } finally {
      setIsFetchingProducts(false);
    }
  };

  const generateSearchVariations = (searchTerm) => {
    const variations = [searchTerm]; // Start with the original search term
    const termLength = searchTerm.length;

    // Simple approach: add a space after every character (naive and not exhaustive)
    for (let i = 1; i < termLength; i++) {
      variations.push(`${searchTerm.slice(0, i)} ${searchTerm.slice(i)}`);
    }


    return variations;
  };

  const liveSearchProducts = async (searchTerm) => {
    // Check if searchTerm is defined and is a string
    if (typeof searchTerm !== "string") {
      // If not, set isSearchActive to false, clear products, and return early
      setIsSearchActive(false);
      setProducts([]);
      setNoProductsFound(false);
      return;
    }

    // Trim leading and trailing whitespace from the search term
    searchTerm = searchTerm.trim();

    if (!searchTerm) {
      setIsSearchActive(false);
      setProducts([]);
      setNoProductsFound(false);
      return;
    }

    setIsFetchingProducts(true);
    setIsSearchActive(true);

    // Generate search term variations
    const searchTermVariations = generateSearchVariations(searchTerm);

    try {
      const searchPromises = searchTermVariations.map((variation) =>
        axios.get(
          `https://trio-teach-backend-ecom.vercel.app/admin/search?query=${encodeURIComponent(
            variation
          )}`,
          { headers: getAuthHeader() }
        )
      );

      // Wait for all search requests to finish
      const responses = await Promise.all(searchPromises);

      // Combine products from all responses
      const combinedProducts = responses.flatMap((response) => response.data);

      // Filter out duplicate products based on a unique identifier (e.g., product ID)
      const uniqueProducts = Array.from(
        new Map(
          combinedProducts.map((product) => [product["_id"], product])
        ).values()
      );

      setProducts(uniqueProducts);
      setNoProductsFound(uniqueProducts.length === 0);
    } catch (error) {
      console.error("An error occurred while searching for products:", error);
      setNoProductsFound(true);
    } finally {
      setIsFetchingProducts(false);
    }
  };

  // Inside your component
  const debouncedSearch = debounce((query) => {
    liveSearchProducts(query);
  }, 500); // Adjust the delay as needed

  useEffect(() => {
    // Call liveSearchProducts whenever searchTerm changes and is not empty
    if (searchTerm) {
      liveSearchProducts(searchTerm);
    } else {
      // Clear search results if searchTerm is empty
      setProducts([]);
      setIsSearchActive(false);
    }
  }, [searchTerm]);

  return (
    <div className="site-header-wrapper">
      <div className="site-header">
        <div className="site-header__top-section">
          {/* Brand and Logo Section */}
          <header className="brand-area__title">
            <Link to="/home" className="brand-link__trio">
              <h1 className="brand-title__trio">
                <span className="brand-highlight__trio">TrioTech</span>{" "}
                Commercial
              </h1>
            </Link>
          </header>

          <div className="logo-area">
            <img src="../images/logo.jpg" alt="" className="logo-area__image" />
          </div>

          {/* Search Bar */}
          <div className="search-area">
            <input
              type="text"
              className="search-area__input"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debouncedSearch(e.target.value);
              }}
            />

            <button
              type="submit"
              className="search-area__button"
              onClick={liveSearchProducts}
            >
              <CiSearch className="search-area__icon" />
            </button>
          </div>

          {/* User Actions */}
          <div className="user-actions">
            <Link to="/order-history" id="order-history-link">
              <FaHistory />
            </Link>
            <Link to="/api/cart" id="view-cart-link">
              <FaCartArrowDown />
            </Link>
            <Link to="/favorites" id="view_fav_btn">
              <MdOutlineFavorite />
            </Link>
            <Link to="/tracking" id="view_trc_btn">
              <FaFedex />
            </Link>
            <button
              onClick={() => AuthService.logout()}
              id="logout-link-user"
              className="link-button"
            >
              Logout
            </button>
          </div>
        </div>

        {/* Department Navigation */}
        <nav className="site-navigation">
          {departments.map((department) => (
            <div
              key={department._id}
              className="dropdown"
              onMouseEnter={() => {
                setIsHovering(true);
                setProducts([]);
              }}
              onMouseLeave={() => setIsHovering(false)}
            >
              <button className="dropbtn">{department.name}</button>
              <div className="dropdown-content">
                {categories
                  .filter((category) => category.department === department._id)
                  .map((category) => (
                    <div
                      key={category._id}
                      className="category-item_header"
                      onMouseEnter={() => fetchProducts(category._id)}
                    >
                      {category.name}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </nav>

        {/* Products Display Section when hovering over categories */}
        {isHovering && activeCategory && !isSearchActive && (
          <div className="products-dropdown">
            {isFetchingProducts ? (
              <div className="product-item_header">Loading...</div>
            ) : noProductsFound ? (
              <div className="product-item_header">No products found</div>
            ) : (
              products.map((product) => (
                <div key={product._id} className="product-item_header">
                  {product.name}
                </div>
              ))
            )}
          </div>
        )}

        {/* Products Display Section when search is active */}
        {isSearchActive && (
          <div className="products-display_search">
            {isFetchingProducts ? (
              <div className="product-item_header">Searching...</div>
            ) : noProductsFound ? (
              <div className="product-item_header">No products found</div>
            ) : (
              products.map((product) => (
                <div key={product._id} className="product-item_header">
                  {product.name}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Category.css";
import { Link } from "react-router-dom";

const API_BASE_URL_CATEGORIES =
  "https://trio-teach-backend-ecom.vercel.app/admin/categories/";


function CategoryList() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = () => {
    axios
      .get(`${API_BASE_URL_CATEGORIES}public/all`)
      .then((response) => {
        console.log("Fetched Categories:", response.data);
        setCategories(response.data);
      })
      .catch((error) => console.error("Failed to fetch categories:", error));
  };

  return (
    <div className="category-container">
      <header className="brand-area__trio">
        <Link to="/home" className="brand-link__trio">
          <h1 className="brand-title__trio">
            <span className="brand-highlight__trio">TrioTech</span> Commercial
          </h1>
        </Link>
      </header>
      <h2 className="category-heading">Category List</h2>
      <ul className="category-list">
        {categories.map((category) => (
          <li key={category._id} className="category-item">
            <strong>{category.name}</strong> -{" "}
            <span>{category.description}</span> -{" "}
            <i>
              <b>
                {category.department
                  ? category.department.name
                  : "No department"}
              </b>
            </i>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoryList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/HomeSlidePics.css";
function HomeSlidePicturesList() {
  const [slidePictures, setSlidePictures] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchSlidePictures = async () => {
      try {
        const response = await axios.get(
          "https://trio-teach-backend-ecom.vercel.app/admin/homeSlidePicturesList"
        );
        setSlidePictures(response.data.flatMap((slide) => slide.images));
      } catch (error) {
        console.error("Failed to fetch slide pictures:", error.response);
      }
    };

    fetchSlidePictures();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % slidePictures.length);
    }, 8500);

    return () => clearInterval(interval);
  }, [currentIndex, slidePictures.length]);

  const previousSlide = () => {
    setCurrentIndex(
      (currentIndex - 1 + slidePictures.length) % slidePictures.length
    );
  };

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % slidePictures.length);
  };

  return (
    <div className="unique-gallery-container">
      <div className="unique-gallery-slideshow">
        <button className="unique-gallery-nav prev" onClick={previousSlide}>
          &lt;
        </button>
        {slidePictures.length > 0 && (
          <div className="unique-image-wrapper">
            <img
              src={slidePictures[currentIndex]}
              alt={`Gallery Slide ${currentIndex + 1}`}
              className="unique-fade-image"
            />
          </div>
        )}
        <button className="unique-gallery-nav next" onClick={nextSlide}>
          &gt;
        </button>
      </div>
    </div>
  );
}

export default HomeSlidePicturesList;

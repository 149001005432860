import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import { Link } from "react-router-dom";

import "../css/FavoritesDisplay.css";
import Footer from "./Footer";

function FavoritesDisplay() {
  const [favorites, setFavorites] = useState([]);

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app/api";

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const user = AuthService.getCurrentUser();
        if (user) {
          const response = await axios.get(`${API_BASE_URL}/favorites`, {
            headers: { Authorization: `Bearer ${user.token}` },
          });
          setFavorites(response.data.items);
        } else {
          alert("Please log in to view favorites");
        }
      } catch (error) {
        console.error("Failed to fetch favorites:", error);
      }
    };

    fetchFavorites();
  }, []);

  const removeFromFavorites = async (productId) => {
    try {
      const user = AuthService.getCurrentUser();
      if (!user) {
        alert("Please log in to remove items from favorites");
        return;
      }

      await axios.delete(`${API_BASE_URL}/favorites/item/${productId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      alert("Product removed from favorites!");
      // Refresh the favorites list to reflect the change
      const response = await axios.get(`${API_BASE_URL}/favorites`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setFavorites(response.data.items);
    } catch (error) {
      console.error("Error removing product from favorites:", error);
      alert("Failed to remove product from favorites.");
    }
  };

  const moveFavoritesToCart = async () => {
    try {
      const user = AuthService.getCurrentUser();
      if (!user) {
        alert("Please log in to move favorites to cart");
        return;
      }

      await axios.post(
        `${API_BASE_URL}/favorites/moveToCart`,
        {}, // Empty body
        { headers: { Authorization: `Bearer ${user.token}` } }
      );

      alert("Favorites moved to cart successfully!");
      setFavorites([]); // Clear favorites in state
    } catch (error) {
      console.error("Error moving favorites to cart:", error);
      alert("Failed to move favorites to cart.");
    }
  };

  return (
    <>
      <div className="favorites-display-container__">
        <div className="favorites-display-container">
          <header className="brand-area__trio">
            <Link to="/home" className="brand-link__trio">
              <h1 className="brand-title__trio">
                <span className="brand-highlight__trio">TrioTech</span>{" "}
                Commercial
              </h1>
            </Link>
          </header>
          <h1 className="favorites-heading">Your Favorites</h1>
          <div className="favorites-list">
            {favorites.length > 0 ? (
              favorites.map((item, index) => (
                <div key={index} className="favorite-item">
                  <h3 className="favorite-item-name">{item.productId.name}</h3>
                  <p className="favorite-item-brand">
                    Brand: {item.productId.brand}
                  </p>
                  {item.productId.images && item.productId.images[0] && (
                    <img
                      src={item.productId.images[0]}
                      alt={item.productId.name}
                      className="favorite-item-image"
                    />
                  )}
                  <button
                    className="favorite-remove-btn"
                    onClick={() => removeFromFavorites(item.productId._id)}
                  >
                    Remove from Favorites
                  </button>
                  <button
                    className="move-all-favorites-btn"
                    onClick={moveFavoritesToCart}
                  >
                    Move All Favorites to Cart
                  </button>
                </div>
              ))
            ) : (
              <p className="no-favorites-message">No favorites added yet.</p>
            )}
          </div>
        </div>
      </div>
      <div className="footer__container">
        <Footer />
      </div>
    </>
  );
}

export default FavoritesDisplay;

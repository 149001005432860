import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/Tracker.css";

function Tracker() {
  const [orderId, setOrderId] = useState("");
  const [trackingInfo, setTrackingInfo] = useState({
    shippingId: "",
    comments: "",
    status: "",
    shippingCompanyName: "",
    shippingTrackerId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [trackingInfos, setTrackingInfos] = useState([]);

  useEffect(() => {
    const savedTrackingInfos = localStorage.getItem("trackingInfos");
    if (savedTrackingInfos) {
      setTrackingInfos(JSON.parse(savedTrackingInfos));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "orderId") {
      setOrderId(value);
    } else {
      setTrackingInfo({ ...trackingInfo, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { token } = AuthService.getCurrentUser();
    try {
      const response = await axios.post(
        `https://trio-teach-backend-ecom.vercel.app/admin/orders/${orderId}/tracking`,
        { ...trackingInfo, orderId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      setSubmissionMessage("Tracking information added successfully!");
      const newTrackingInfos = [...trackingInfos, response.data];
      setTrackingInfos(newTrackingInfos);
      localStorage.setItem("trackingInfos", JSON.stringify(newTrackingInfos));
      resetForm();

      // Log the response data
      console.log("Response Data:", response.data);
    } catch (error) {
      setIsLoading(false);
      setSubmissionMessage(
        "Error adding tracking information. Please try again."
      );
      console.error("Error adding tracking information:", error);
    }
  };

  const resetForm = () => {
    setOrderId("");
    setTrackingInfo({
      shippingId: "",
      comments: "",
      status: "",
      shippingCompanyName: "",
      shippingTrackerId: "",
    });
  };

  const handleDeleteTracking = async (trackingId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this tracking information?"
      )
    ) {
      const { token } = AuthService.getCurrentUser();
      try {
        await axios.delete(
          `https://trio-teach-backend-ecom.vercel.app/admin/tracking/${trackingId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubmissionMessage("Tracking information deleted successfully!");
        
        const updatedTrackingInfos = trackingInfos.filter(
          (info) => info._id !== trackingId
        );
        setTrackingInfos(updatedTrackingInfos);
        localStorage.setItem(
          "trackingInfos",
          JSON.stringify(updatedTrackingInfos)
        );

       
        console.log("Deleted Tracking ID:", trackingId);
      } catch (error) {
        console.error("Error deleting tracking information:", error);
        setSubmissionMessage(
          "Failed to delete tracking information. Please try again."
        );
      }
    }
  };

  return (
    <div id="trackingFormContainer">
      <h3 id="trackingFormTitle">Add / Update Tracking Information</h3>
      {submissionMessage && <p id="submissionMessage">{submissionMessage}</p>}
      <form id="trackingForm" onSubmit={handleSubmit}>
        <div className="formFieldRow">
          <div className="formField">
            <label htmlFor="orderId">Order ID:</label>
            <input
              type="text"
              id="orderId"
              name="orderId"
              value={orderId}
              onChange={handleChange}
            />
          </div>
          <div className="formField">
            <label htmlFor="shippingId">Shipping ID:</label>
            <input
              type="text"
              id="shippingId"
              name="shippingId"
              value={trackingInfo.shippingId}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="formFieldRow">
          <div className="formField">
            <label htmlFor="comments">Comments:</label>
            <input
              type="text"
              id="comments"
              name="comments"
              value={trackingInfo.comments}
              onChange={handleChange}
            />
          </div>
          <div className="formField">
            <label htmlFor="status">Status:</label>
            <select
              id="status"
              name="status"
              value={trackingInfo.status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="preparing">Preparing</option>
              <option value="sent">Sent</option>
            </select>
          </div>
        </div>

        <div className="formFieldRow">
          <div className="formField">
            <label htmlFor="shippingCompanyName">Shipping Company Name:</label>
            <input
              type="text"
              id="shippingCompanyName"
              name="shippingCompanyName"
              value={trackingInfo.shippingCompanyName}
              onChange={handleChange}
            />
          </div>
          <div className="formField">
            <label htmlFor="shippingTrackerId">Shipping Tracker ID:</label>
            <input
              type="text"
              id="shippingTrackerId"
              name="shippingTrackerId"
              value={trackingInfo.shippingTrackerId}
              onChange={handleChange}
            />
          </div>
        </div>
        <button id="submitButton" type="submit" disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </form>

      {trackingInfos.length > 0 && (
        <div id="submittedTrackingInfoContainer">
          <h4 id="submittedTrackingInfoTitle">
            Submitted Tracking Information:
          </h4>
          <div className="table-responsive">
            <table id="trackingInfoTable">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Shipping ID</th>
                  <th>Comments</th>
                  <th>Status</th>
                  <th>Shipping Company Name</th>
                  <th>Shipping Tracker ID</th>
                  <th>Actions</th> {/* Add a column for actions */}
                </tr>
              </thead>
              <tbody>
                {trackingInfos.map((info, index) => (
                  <tr key={index}>
                    <td>{info.orderId}</td>
                    <td>{info.shippingId}</td>
                    <td>{info.comments}</td>
                    <td>{info.status}</td>
                    <td>{info.shippingCompanyName}</td>
                    <td>{info.shippingTrackerId}</td>
                    <td>
                      <button
                        className="delete-btn-style"
                        onClick={() => handleDeleteTracking(info._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default Tracker;

import React, { useState } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/AddSlidePictures.css";

function AddSlidePictures() {
  const [images, setImages] = useState(Array(7).fill(""));
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get the current user from AuthService
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser || !currentUser.token) {
      setMessage("You must be logged in to upload images.");
      return;
    }

    try {
      const response = await axios.post(
        "https://trio-teach-backend-ecom.vercel.app/admin/slide-pictures",
        { images },
        {
          headers: {
            // Use the token from currentUser for authorization
            Authorization: `Bearer ${currentUser.token}`,
          },
        }
      );
      setMessage("Images uploaded successfully!");
      // Log the response for debugging purposes
      console.log("Response Data:", response.data);
    } catch (error) {
      setMessage(error.response.data.message || "An error occurred");
      // Log the error for debugging purposes
      console.error("Error Response:", error.response);
    }
  };

  return (
    <div className="add-slide-container">
      <h2 className="add-slide-title">Add User Dashboard Slide Pictures</h2>
      <form onSubmit={handleSubmit} className="add-slide-form">
        {images.map((_, index) => (
          <input
            key={index}
            type="text"
            className="add-slide-input"
            placeholder={`Image URL ${index + 1}`}
            value={images[index]}
            onChange={(e) => {
              const newImages = [...images];
              newImages[index] = e.target.value;
              setImages(newImages);
            }}
          />
        ))}
        <button type="submit" className="add-slide-submit-btn">
          Submit
        </button>
      </form>
      {message && <p className="add-slide-message">{message}</p>}
    </div>
  );
}

export default AddSlidePictures;

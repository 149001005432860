import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/Orders.css";

// const API_BASE_URL_ORDERS = "http://localhost:3000/admin/orders/";
const API_BASE_URL_ORDERS = "https://trio-teach-backend-ecom.vercel.app/admin/orders/";

function Orders() {
  const [orders, setOrders] = useState([]);

  const fetchAllOrders = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${AuthService.getCurrentUser().token}`,
      };
      const response = await axios.get(`${API_BASE_URL_ORDERS}all`, {
        headers,
      });
      setOrders(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    }
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const handleEditStatus = async (orderId) => {
    const newStatus = prompt(`Enter new status for order ${orderId}:`);
    if (newStatus) {
      try {
        const headers = {
          Authorization: `Bearer ${AuthService.getCurrentUser().token}`,
        };
        await axios.put(
          `${API_BASE_URL_ORDERS}/updateStatus/${orderId}`,
          { status: newStatus },
          { headers }
        );
        // Refresh order list
        fetchAllOrders();
      } catch (error) {
        console.error("Failed to update order status:", error);
      }
    }
  };
  const handleDeleteOrder = async (orderId) => {
    if (window.confirm(`Are you sure you want to delete order ${orderId}?`)) {
      try {
        const headers = {
          Authorization: `Bearer ${AuthService.getCurrentUser().token}`,
        };
        await axios.delete(`${API_BASE_URL_ORDERS}çdelete/${orderId}`, {
          headers,
        });
        // Refresh the order list to reflect the deletion
        fetchAllOrders();
      } catch (error) {
        console.error("Failed to delete order:", error);
      }
    }
  };

  return (
    <div id="orders-container">
      <h2 id="orders-title">Order List</h2>
      <div id="orders-list">
        {orders.length > 0 ? (
          orders.map((order) => (
            <div
              key={order._id}
              id={`order-card-${order._id}`}
              className="order-card-style"
            >
              <div
                id={`order-header-${order._id}`}
                className="order-header-style"
              >
                <span
                  id={`order-date-${order._id}`}
                  className="order-date-style"
                >
                  ORDER PLACED {new Date(order.createdAt).toLocaleDateString()}
                </span>
                <span
                  id={`order-total-${order._id}`}
                  className="order-total-style"
                >
                  TOTAL ${order.totalPrice.toFixed(2)}
                </span>
                <span id={`order-id-${order._id}`} className="order-id-style">
                  ORDER # {order._id}
                </span>
                <span
                  id={`user-email-${order._id}`}
                  className="user-email-style"
                >
                  {order.email}
                </span>
              </div>
              <div
                id={`product-details-${order._id}`}
                className="product-details-style"
              >
                {order.items.map((item, index) => (
                  <div
                    key={index}
                    id={`item-details-${order._id}-${index}`}
                    className="item-details-style"
                  >
                    <img
                      src={
                        item.productId.images && item.productId.images[0]
                          ? item.productId.images[0]
                          : "default-image-url"
                      }
                      alt={item.name}
                      id={`product-image-${order._id}-${index}`}
                      className="product-image-style"
                    />
                    <div
                      id={`item-info-${order._id}-${index}`}
                      className="item-info-style"
                    >
                      <p>
                        <strong>{item.name}</strong>
                      </p>
                      <p>Quantity: {item.quantity}</p>
                      <p>Price: ${item.price.toFixed(2)}</p>
                    </div>
                    {index < order.items.length - 1 && <hr />}
                  </div>
                ))}
              </div>
              <div
                id={`order-footer-${order._id}`}
                className="order-footer-style"
              >
                <span
                  id={`payment-status-${order._id}`}
                  className="payment-status-style"
                >
                  {order.paymentStatus}
                </span>
                <button
                  id={`edit-btn-${order._id}`}
                  className="edit-btn-style"
                  onClick={() => handleEditStatus(order._id)}
                >
                  Edit Status
                </button>
                <button
                  id={`delete-btn-${order._id}`}
                  className="delete-btn-style"
                  onClick={() => handleDeleteOrder(order._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No orders found.</p>
        )}
      </div>
    </div>
  );
}

export default Orders;

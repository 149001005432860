import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/ProductDetails.css";

const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app/admin";

function AddProductAndUpdateDetails() {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [attributeValues, setAttributeValues] = useState({});
  const [imageUrls, setImageUrls] = useState(Array(5).fill(""));
  const [updateMode, setUpdateMode] = useState(false);

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      fetchCategories(selectedDepartment);
    } else {
      setCategories([]);
      setSelectedCategory("");
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedCategory) {
      fetchProducts(selectedCategory);
    } else {
      setProducts([]);
      setSelectedProduct("");
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedProduct) {
      fetchAttributes(selectedProduct);
    } else {
      setAttributes([]); // Reset attributes when no product is selected
    }
  }, [selectedProduct]);

  const getAuthHeader = () => {
    const user = AuthService.getCurrentUser();
    return user ? { Authorization: `Bearer ${user.token}` } : {};
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/departments/all`, {
        headers: getAuthHeader(),
      });
      setDepartments(response.data);
      console.log("Departments", response.data);
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  const fetchCategories = async (departmentId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/categories/by-department/${departmentId}`,
        {
          headers: getAuthHeader(),
        }
      );
      setCategories(response.data);
      console.log("Categories", response.data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchProducts = async (categoryId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/products/by-category/${categoryId}`,
        {
          headers: getAuthHeader(),
        }
      );
      setProducts(response.data);
      console.log("Products", response.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  const fetchAttributes = async (productId) => {
    if (!productId) return; // Exit early if no productId is provided

    try {
      const response = await axios.get(
        `${API_BASE_URL}/attributes/by-product/${productId}`,
        {
          headers: getAuthHeader(),
        }
      );
      setAttributes(response.data);
      console.log("Attributes", response.data);
      initializeAttributeValues(response.data);
      console.log("Attributes2", response.data);
    } catch (error) {
      console.error("Failed to fetch attributes:", error);
    }
  };

  const initializeAttributeValues = (fetchedAttributes) => {
    const initialValues = {};
    fetchedAttributes.forEach((attribute) => {
      initialValues[attribute._id] = "";
    });
    setAttributeValues(initialValues);
  };

  const handleAttributeValueChange = (attributeId, value) => {
    setAttributeValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }));
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      images: imageUrls.filter((url) => url !== ""),
      attributes: Object.keys(attributeValues).map((key) => ({
        key,
        value: attributeValues[key],
      })),
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/products/${selectedProduct}`, // Ensure this matches your backend route
        payload,
        { headers: getAuthHeader() }
      );
      alert("Product details added successfully.");
      console.log("Response from server:", response.data);
    } catch (error) {
      console.error("Error adding product details:", error);
      alert(
        "Failed to add product details. Please check the console for more details."
      );
    }
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      images: imageUrls.filter((url) => url !== ""),
      attributes: Object.keys(attributeValues).map((key) => ({
        key,
        value: attributeValues[key],
      })),
    };

    try {
      const response = await axios.put(
        `${API_BASE_URL}/products/${selectedProduct}`, // Ensure this matches your backend route
        payload,
        { headers: getAuthHeader() }
      );
      alert("Product details updated successfully.");
      console.log("Response from server:", response.data);
    } catch (error) {
      console.error("Error updating product details:", error);
      alert(
        "Failed to update product details. Please check the console for more details."
      );
    }
  };

  const toggleUpdateMode = () => {
    setUpdateMode(!updateMode);
  };

  return (
    <div className="product-details-container">
      <h2>{updateMode ? "Update Product Details" : "Add Product Details"}</h2>
      <button onClick={toggleUpdateMode} style={{ marginBottom: "20px" }}>
        {updateMode ? "Switch to Add Mode" : "Switch to Update Mode"}
      </button>
      <form onSubmit={updateMode ? handleUpdateSubmit : handleAddSubmit}>
        <select
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          required
        >
          <option value="">Select Department</option>
          {departments.map((department) => (
            <option key={department._id} value={department._id}>
              {department.name}
            </option>
          ))}
        </select>

        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          required
          disabled={!selectedDepartment}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>

        <select
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          required
          disabled={!selectedCategory}
        >
          <option value="">Select Product</option>
          {products.map((product) => (
            <option key={product._id} value={product._id}>
              {product.name}
            </option>
          ))}
        </select>

        <div
          className="attributes-section"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <h3>Attributes</h3>
          <p>Please set the values for each product attribute:</p>
          {attributes.length > 0 ? (
            attributes.map((attribute) => (
              <div key={attribute._id} style={{ marginBottom: "10px" }}>
                <label
                  htmlFor={`attribute-${attribute._id}`}
                  style={{ marginRight: "10px" }}
                >
                  {attribute.key}:
                </label>
                <select
                  id={`attribute-${attribute._id}`}
                  value={attributeValues[attribute._id]}
                  onChange={(e) =>
                    handleAttributeValueChange(attribute._id, e.target.value)
                  }
                  required
                >
                  <option value="">Select Value</option>
                  {attribute.values.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            ))
          ) : (
            <p>No attributes to display. Please select a product.</p>
          )}
        </div>

        <h3>Image URLs</h3>
        {imageUrls.map((url, index) => (
          <div key={index}>
            <input
              type="text"
              placeholder={`Image URL ${index + 1}`}
              value={url}
              onChange={(e) => {
                const newImageUrls = [...imageUrls];
                newImageUrls[index] = e.target.value;
                setImageUrls(newImageUrls);
              }}
            />
            {imageUrls.length > 1 && (
              <button
                type="button"
                onClick={() => {
                  const filteredImageUrls = imageUrls.filter(
                    (_, i) => i !== index
                  );
                  setImageUrls(filteredImageUrls);
                }}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        {imageUrls.length < 5 && (
          <button
            type="button"
            onClick={() => setImageUrls([...imageUrls, ""])}
          >
            Add Another Image URL
          </button>
        )}

        <button type="submit" style={{ marginTop: "20px", cursor: "pointer" }}>
          {updateMode ? "Update Details" : "Add Details"}
        </button>
      </form>
    </div>
  );
}

export default AddProductAndUpdateDetails;

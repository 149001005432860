import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../components/bestProduct/BestProduct.css";

const BestProductList = () => {
  const [bestProducts, setBestProducts] = useState([]);

  useEffect(() => {
    const fetchBestProducts = async () => {
      try {
        const response = await axios.get(
          "https://trio-teach-backend-ecom.vercel.app/product/best-deals"
        );
        setBestProducts(response.data);
      } catch (error) {
        console.error("Error fetching best products:", error);
      }
    };

    fetchBestProducts();
  }, []);

  return (
    <div className="fancy-best-products-list">
      {bestProducts.map((product, index) => (
        <div key={index} className="fancy-product-card">
          <div className="fancy-product-image" style={{ backgroundImage: `url(${product.imageUrl[0]})` }}></div>
          <div className="fancy-product-details">
            <h3 className="fancy-product-name">{product.productName}</h3>
            <p className="fancy-original-price">Original: ${product.originalPrice}</p>
            <p className="fancy-discount-price">Discount: ${product.discountPrice}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BestProductList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/FetchShippingAdmin.css";

function FetchShippingAdmin() {
  const [shippings, setShippings] = useState([]);

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app/shipping";

  useEffect(() => {
    fetchShippings();
  }, []);

  const fetchShippings = async () => {
    try {
      const { token } = AuthService.getCurrentUser();
      const response = await axios.get(`${API_BASE_URL}/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShippings(response.data);
    } catch (error) {
      console.error("Error fetching shippings:", error);
    }
  };

  const handleDeleteShipping = async (id) => {
    if (window.confirm("Are you sure you want to delete this shipping information?")) {
      try {
        const { token } = AuthService.getCurrentUser();
        await axios.delete(`${API_BASE_URL}/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShippings(shippings.filter((shipping) => shipping._id !== id));
      } catch (error) {
        console.error("Error deleting shipping information:", error);
      }
    }
  };

  return (
    <div id="shipping-container">
      <h2 id="shipping-title">All Shipping Information</h2>
      {shippings.length > 0 ? (
        <div id="shipping-list">
          {shippings.map((shipping, index) => (
            <div key={shipping._id} id={`shipping-item-${index}`} className="shipping-item">
              <div id="shipping-item-header">
                <h3>Shipping #{index + 1}</h3>
                <span id="shipping-id">ID: {shipping._id}</span>
                
              </div>
              <div id="shipping-item-body">
                <p id="shipping-username"><b>Username:</b> {shipping.userId ? shipping.userId.username : "N/A"}</p>
                <p id="shipping-email"><b>Email:</b> {shipping.userId ? shipping.userId.email : "N/A"}</p>
                <p id="shipping-country"><b>Country:</b> {shipping.country}</p>
                <p id="shipping-address"><b>Address:</b> {shipping.address}</p>
                <p id="shipping-province"><b>Province:</b> {shipping.province}</p>
                <p id="shipping-postal-code"><b>Postal Code:</b> {shipping.postalCode}</p>
              </div>
              <div>
              <button className="delete-btn-style" onClick={() => handleDeleteShipping(shipping._id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p id="shipping-no-info">No shipping information found.</p>
      )}
    </div>
  );
}

export default FetchShippingAdmin;

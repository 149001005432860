import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/Inventory.css";

function AdminInventory() {
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [productQuantity, setProductQuantity] = useState("");

  const API_URL = "https://trio-teach-backend-ecom.vercel.app/admin/products";

  useEffect(() => {
    const fetchProducts = async () => {
      const user = AuthService.getCurrentUser();
      if (!user || !user.token) {
        alert("You must be logged in as an admin.");
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/all`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        setProducts(response.data);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // When selectedProductId changes, update the productQuantity to reflect the current quantity
    const selectedProduct = products.find(
      (product) => product._id === selectedProductId
    );
    if (selectedProduct) {
      setProductQuantity(selectedProduct.productQuantity);
    }
  }, [selectedProductId, products]);

  const updateInventory = async (e) => {
    e.preventDefault();
    const user = AuthService.getCurrentUser();
    if (!user || !user.token) {
      alert("You must be logged in as an admin to update inventory.");
      return;
    }

    try {
      await axios.put(
        `${API_URL}/inventory/${selectedProductId}`,
        { productQuantity: parseInt(productQuantity, 10) },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      alert("Inventory updated successfully");
      setSelectedProductId("");
      setProductQuantity("");
    } catch (error) {
      console.error(
        "Failed to update inventory:",
        error.response?.data?.message || error.message
      );
      alert("Failed to update inventory.");
    }
  };

  return (
    <div id="inventory-form-container">
      <h2 id="inventory-form-heading">Update Product Inventory</h2>
      <form onSubmit={updateInventory} id="inventory-form">
        <div id="inventory-form-product" className="form-field-container">
          <label htmlFor="product-select" className="form-label_best_product">
            Product:
          </label>
          <select
            id="product-select"
            className="form-select"
            value={selectedProductId}
            onChange={(e) => setSelectedProductId(e.target.value)}
            required
          >
            <option value="">Select a product</option>
            {products.map((product) => (
              <option
                key={product._id}
                value={product._id}
              >{`${product.name} (Current Quantity: ${product.productQuantity})`}</option>
            ))}
          </select>
        </div>
        <div id="inventory-form-quantity" className="form-field-container">
          <label htmlFor="quantity-input" className="form-label_best_product">
            Quantity:
          </label>
          <input
            id="quantity-input"
            type="number"
            className="form-input"
            value={productQuantity}
            onChange={(e) => setProductQuantity(e.target.value)}
            min="0"
            required
          />
        </div>
        <button type="submit" className="submit-btn">
          Update Inventory
        </button>
      </form>
    </div>
  );
}

export default AdminInventory;

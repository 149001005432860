import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import AuthService from "../auth/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { createOrderFromCart } from "./orderService";

import "../css/PaymentForm.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#333",
      fontWeight: 500,
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased", 
      "::placeholder": { 
        color: "#87bbfd",
        fontWeight: 400 
      },
    },
    invalid: {
      iconColor: "#ff5f5f",
      color: "#d9534f",
    },
  },
};


function PaymentForm({ onSuccess, onFailure, shippingInfo, cartItems }) {
  const navigate = useNavigate();
  const location = useLocation();
  const totalPrice = location.state?.totalPrice ?? 0;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    console.log("Starting payment process...");

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
  
      if (error) {
        throw new Error(error.message);
      }
      console.log("Payment method created:", paymentMethod);

      // Make the payment
      console.log("Attempting payment with totalPrice:", totalPrice);
      const paymentResponse = await axios.post(`${API_BASE_URL}/payment/charge`, {
        totalPrice,
        token: paymentMethod.id,
      }, {
        headers: { Authorization: `Bearer ${AuthService.getCurrentUser().token}` },
      });
      console.log("Payment response:", paymentResponse.data);

      if (!paymentResponse.data.success) {
        throw new Error(paymentResponse.data.message || "Payment failed");
      }

      console.log("Payment successful");

      // Save shipping information if present
      let shippingId = null;
      if (shippingInfo) {
        console.log("Submitting shipping info:", shippingInfo);
        const shippingData = { ...shippingInfo, userId: AuthService.getCurrentUser().userId };
        const shippingResponse = await axios.post(`${API_BASE_URL}/shipping/add`, shippingData, {
          headers: { Authorization: `Bearer ${AuthService.getCurrentUser().token}` },
        });
        console.log("Shipping response:", shippingResponse.data);

        if (!shippingResponse.data._id) {
          throw new Error("Failed to insert shipping information into the database.");
        }

        shippingId = shippingResponse.data._id;
        console.log("Shipping ID received:", shippingId);
      } else {
        console.log("No shipping info provided");
      }

      // Create the order with the shippingId
      console.log("Creating order with shipping ID:", shippingId);
      const orderData = {
        items: cartItems.map(item => ({ productId: item.productId, quantity: item.quantity })),
        totalPrice,
        shippingId,
      };

      const orderResponse = await createOrderFromCart(orderData);
      console.log("Order response:", orderResponse.data);

      if (!orderResponse.data || !orderResponse.data._id) {
        throw new Error("Order creation response did not include an order ID.");
      }

      console.log("Order created successfully:", orderResponse.data);
      onSuccess && onSuccess(orderResponse.data);
      navigate(`/order-confirmation`, { state: { orderId: orderResponse.data._id } });
    } catch (error) {
      console.error("Error during payment or order processing:", error);
      setError(error.message || "An unknown error occurred");
      onFailure && onFailure(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="PaymentForm">
      {!loading && !error ? (
        <form onSubmit={handleSubmit} className="PaymentForm__Form">
          <fieldset disabled={loading} className="PaymentForm__Fieldset">
            <div className="FormRow PaymentForm__Row">
              <CardElement options={CARD_OPTIONS} className="PaymentForm__CardElement" />
            </div>
            <div className="TotalPrice PaymentForm__TotalPrice">
              <p className="TotalPrice__Text">Total Price: ${totalPrice.toFixed(2)}</p>
            </div>
            {error && <div className="ErrorMsg PaymentForm__ErrorMsg">{error}</div>}
          </fieldset>
          <button disabled={!stripe || loading} className="PaymentForm__Button">Proceed to Payment</button>
        </form>
      ) : (
        <div className="PaymentForm__Status">
          <h2 className="Status__Heading">{error ? "Payment Error: " + error : "Processing Payment..."}</h2>
        </div>
      )}
    </div>
  );
  
}

export default PaymentForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import "../css/OrderHistory.css";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function OrderHistory() {
  const [orders, setOrders] = useState([]);

  const API_URL =
    "https://trio-teach-backend-ecom.vercel.app/admin/orders/displayUserOrderHistory";

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const user = AuthService.getCurrentUser();
        if (!user) {
          console.log("User not logged in");
          return;
        }

        const headers = {
          Authorization: `Bearer ${user.token}`,
        };
        const response = await axios.get(`${API_URL}`, { headers });
        setOrders(response.data);
      } catch (error) {
        console.error("Failed to fetch order history:", error);
      }
    };

    fetchOrderHistory();
  }, []);

  return (
    <>
      <div id="order-history-container__">
        <div id="order-history-container">
          <header className="brand-area__trio">
            <Link to="/home" className="brand-link__trio">
              <h1 className="brand-title__trio">
                <span className="brand-highlight__trio">TrioTech</span>{" "}
                Commercial
              </h1>
            </Link>
          </header>
          <h2 id="order-history-title">Order History</h2>
          {orders.length > 0 ? (
            <div id="order-list">
              {orders.map((order) => (
                <div key={order._id} id="order-card">
                  <div id="order-info">
                    <span id="order-id">Order ID: {order._id}</span>
                    <span id="order-total">
                      Total Price: ${order.totalPrice.toFixed(2)}
                    </span>
                  </div>
                  <div id="user-info">
                    <span id="user-id">User ID: {order.userId}</span>
                    <span id="user-email">Email: {order.email}</span>
                  </div>
                  <div id="product-details-container">
                    {order.items.map((item, index) => (
                      <div key={index} id="product-details">
                        <img
                          id="product-image"
                          src={item.productId.images[0]}
                          alt={item.name}
                        />
                        <div id="product-info">
                          <p id="product-name">
                            <strong>Name:</strong> {item.name}
                          </p>
                          <p id="product-price">
                            <strong>Price:</strong> ${item.price}
                          </p>
                          <p id="product-quantity">
                            <strong>Quantity:</strong> {item.quantity}
                          </p>
                        </div>
                        {index < order.items.length - 1 && (
                          <hr id="product-divider" />
                        )}
                      </div>
                    ))}
                  </div>
                  <div id="order-status">
                    <span id="status-text">{order.paymentStatus}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p id="no-orders-message">No order history found.</p>
          )}
        </div>
      </div>
      <div className="footer__container">
        <Footer />
      </div>
    </>
  );
}

export default OrderHistory;

import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import { useNavigate } from "react-router-dom";
import "../css/Cart.css";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function Cart() {
  const [cart, setCart] = useState({ items: [], totalPrice: 0 });
  const navigate = useNavigate();
  const [lastToken, setLastToken] = useState(() => {
    // Initialize lastToken with the current token at component mount
    const user = AuthService.getCurrentUser();
    return user ? user.token : null;
  });
  const checkStockAvailability = () => {
    return cart.items.every(
      (item) => item.quantity <= item.productId.productQuantity
    );
  };
  const stockUnavailable = !checkStockAvailability();
  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app/api/cart";

  // Define fetchCart outside of useEffect so it's accessible everywhere in this component
  const fetchCart = async (token) => {
    if (!token) {
      setCart({ items: [], totalPrice: 0 }); // Clear cart if not logged in
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCart(response.data);
    } catch (error) {
      console.error("Failed to fetch cart:", error.response?.data || error);
    }
  };

  // Define mergeCarts function
  const mergeCarts = async () => {
    const user = AuthService.getCurrentUser();
    if (user && localStorage.getItem("cart")) {
      try {
        const guestCart = JSON.parse(localStorage.getItem("cart"));
        await axios.post(
          `${API_BASE_URL}/merge`,
          { guestCart },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );
        localStorage.removeItem("cart"); // Clear the guest cart after merging

        fetchCart(user.token);
      } catch (error) {
        console.error("Error merging carts:", error);
        // Handle error (e.g., display a message to the user)
      }
    }
  };

  useEffect(() => {
    fetchCart(lastToken);

    // Set up an interval to check for token changes
    const intervalId = setInterval(() => {
      const currentUser = AuthService.getCurrentUser();
      const currentToken = currentUser ? currentUser.token : null;
      if (currentToken !== lastToken) {
        setLastToken(currentToken); // Update lastToken with the new token
        fetchCart(currentToken); // Re-fetch the cart with the new token
      }
    }, 1000); // Check every 1 second

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [lastToken]); // Correctly closed useEffect hook

  const updateQuantity = async (productId, newQuantity) => {
    try {
      const { token } = AuthService.getCurrentUser();
      await axios.put(
        `${API_BASE_URL}/item/${productId}`,
        { quantity: newQuantity },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchCart(token); // Refresh the cart to show updated quantities
    } catch (error) {
      console.error(
        "Failed to update item quantity:",
        error.response?.data || error
      );
    }
  };

  const removeFromCart = async (productId) => {
    try {
      const { token } = AuthService.getCurrentUser();
      await axios.delete(`${API_BASE_URL}/item/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchCart(token); // Refresh the cart to reflect the item removal
    } catch (error) {
      console.error(
        "Failed to remove item from cart:",
        error.response?.data || error
      );
    }
  };

  const proceedToPayment = () => {
    navigate("/payment", { state: { totalPrice: cart.totalPrice } }); // Passing total price to payment page
  };

  // Call mergeCarts when the component mounts
  useEffect(() => {
    mergeCarts();
  }, []); // Empty dependency array to only run once when component mounts

  return (
    <>
      <div className="cart-container__">
        <div className="cart-container">
          <header className="brand-area__trio">
            <Link to="/home" className="brand-link__trio">
              <h1 className="brand-title__trio">
                <span className="brand-highlight__trio">TrioTech</span>{" "}
                Commercial
              </h1>
            </Link>
          </header>
          <h2 className="cart-heading">Your Cart</h2>
          {cart.items.length > 0 ? (
            cart.items.map((item, index) => (
              <div key={index} className="cart-item">
                <h3 className="cart-item-title">{item.productId.name}</h3>
                <img
                  src={item.productId.images[0]}
                  alt={item.productId.name}
                  className="cart-item-image"
                />
                <p className="cart-item-price">
                  Price: ${item.productId.price.toFixed(2)}
                </p>
                <p className="cart-item-quantity">Quantity: {item.quantity}</p>
                {item.quantity > item.productId.productQuantity && (
                  <p className="stock-warning">
                    Stock not available for this quantity!
                  </p>
                )}
                <button
                  className="quantity-btn increase-quantity"
                  onClick={() =>
                    updateQuantity(item.productId._id, item.quantity + 1)
                  }
                  disabled={item.quantity >= item.productId.productQuantity}
                >
                  +
                </button>
                <button
                  className="quantity-btn decrease-quantity"
                  onClick={() =>
                    item.quantity > 1 &&
                    updateQuantity(item.productId._id, item.quantity - 1)
                  }
                >
                  -
                </button>
                <button
                  className="remove-btn"
                  onClick={() => removeFromCart(item.productId._id)}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p className="empty-cart-message">Your cart is empty.</p>
          )}
          <p className="total-price">
            Total Price: ${cart.totalPrice.toFixed(2)}
          </p>
          <button
            className="proceed-btn"
            onClick={proceedToPayment}
            disabled={!checkStockAvailability()}
          >
            Proceed to Payment
          </button>
          {!checkStockAvailability() && (
            <p className="stock-adjustment-warning">
              Adjust quantities as some items exceed available stock.
            </p>
          )}
        </div>
      </div>
      <div className="footer__container">
        <Footer />
      </div>
    </>
  );
}

export default Cart;

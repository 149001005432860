import React from "react";
import AuthService from "../../auth/AuthService";

import "../Admiin/css/sidebar.css";

function Sidebar({ handleMenuClick }) {
  return (
    <div className="sidebar" style={{height:"auto"}}>
          <a href="#" onClick={() => AuthService.logout()} id="logout-link">Logout</a>
          <a href="#" onClick={() => handleMenuClick("dashboard")} id="dashboard-link">Dashboard</a>
          <a href="#" onClick={() => handleMenuClick("users")} id="users-link">Users</a>
          <a href="#" onClick={() => handleMenuClick("departments")} id="departments-link">Departments</a>
          <a href="#" onClick={() => handleMenuClick("attributes")} id="attributes-link">Attributes</a>
          <a href="#" onClick={() => handleMenuClick("categories")} id="categories-link">Categories</a>
          <a href="#" onClick={() => handleMenuClick("products")} id="products-link">Products</a>
          <a href="#" onClick={() => handleMenuClick("inventory")} id="inventory-link">Inventory</a>
          <a href="#" onClick={() => handleMenuClick("orders")} id="orders-link">Orders</a>
          <a href="#" onClick={() => handleMenuClick("shippings")} id="shippings-link">Shippings</a>
          <a href="#" onClick={() => handleMenuClick("tracker")} id="tracker-link">Tracker</a>
          <a href="#" onClick={() => handleMenuClick("slidePictures")} id="slidePictures-link">User-Dashboard Slide Pictures</a>
          <a href="#" onClick={() => handleMenuClick("homeSlidePictures")} id="homeSlidePictures-link">Home Slide Pictures</a>
          <a href="#" onClick={() => handleMenuClick("productDetails")} id="productDetails-link">Product Details</a>
          <a href="#" onClick={() => handleMenuClick("addBestProduct")} id="addBestProduct-link">Add Best Product</a>
        </div>
  );
}

export default Sidebar;

import React, { useState } from "react";
import AuthService from "../auth/AuthService";
import { Link } from "react-router-dom";
import "../css/Signup.css";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await AuthService.signup(
        username,
        email,
        password,
        firstName,
        lastName,
        phoneNumber
      );
      // Redirect user to login page or dashboard page after signup
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <div className="signup__container_all">
      <header className="signup__header">
        <Link to="/home" className="signup__header-link">
          <h1 className="signup__title">
            <span>TrioTech</span> Commercial
          </h1>
        </Link>
      </header>
      <div className="signup">
        <div className="signup__background">
          <div className="signup__container">
            <div className="signup__card">
              <h2 className="signup__heading">Sign Up</h2>
              <form onSubmit={handleSignup} className="signup__form">
                <div className="signup__form-group">
                  <label htmlFor="username" className="signup__label">
                    Username:
                  </label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="signup__input"
                    placeholder="Username"
                    required
                  />
                </div>
                <div className="signup__form-group">
                  <label htmlFor="email" className="signup__label">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="signup__input"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="signup__form-group">
                  <label htmlFor="password" className="signup__label">
                    Password:
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="signup__input"
                    placeholder="Password"
                    required
                  />
                </div>
                <div className="signup__form-group">
                  <label htmlFor="firstName" className="signup__label">
                    First Name:
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="signup__input"
                    placeholder="First Name"
                  />
                </div>
                <div className="signup__form-group">
                  <label htmlFor="lastName" className="signup__label">
                    Last Name:
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="signup__input"
                    placeholder="Last Name"
                  />
                </div>
                <div className="signup__form-group">
                  <label htmlFor="phoneNumber" className="signup__label">
                    Phone Number:
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="signup__input"
                    placeholder="Phone Number"
                  />
                </div>
                <button type="submit" className="signup__submit">
                  Sign Up
                </button>
                <div className="signup__login-link">
                  <p className="signup__login-text">Already have an account?</p>
                  <Link to="/login">
                    <button className="signup__login-button">Log In</button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div className="footer__container">
    <Footer />
  </div>
  </>
  );
}

export default Signup;

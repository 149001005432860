import React, { useEffect, useState } from "react";
import axios from "axios";
import AuthService from "../../auth/AuthService";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

import "../Admiin/css/AdminBarChart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const AdminBarComponent = () => {
  const [orderRevenueChartData, setOrderRevenueChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Revenue",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [shippingDestinationsChartData, setShippingDestinationsChartData] =
    useState({
      labels: [],
      datasets: [
        {
          label: "Shipments by Destination",
          data: [],
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    });

  const [departmentsChartData, setDepartmentsChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Departments",
        data: [],
        backgroundColor: "rgba(255, 159, 64, 0.6)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [categoriesChartData, setCategoriesChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Categories",
        data: [],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  });

  const getAuthHeader = () => {
    const user = AuthService.getCurrentUser();
    if (user && user.token) {
      return { Authorization: `Bearer ${user.token}` };
    } else {
      return {};
    }
  };

  // const API_BASE_URL = "http://localhost:3000/admin/";
  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app/admin/";

  const fetchAllOrders = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}orders/all`, {
        headers: getAuthHeader(),
      });
      // Aggregate total revenue from orders
      const totalRevenue = response.data.reduce(
        (acc, order) => acc + order.totalPrice,
        0
      );
      setOrderRevenueChartData({
        labels: ["Total Revenue"],
        datasets: [
          { ...orderRevenueChartData.datasets[0], data: [totalRevenue] },
        ],
      });
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    }
  };

  const fetchAllShipping = async () => {
    try {
      const response = await axios.get(`https://trio-teach-backend-ecom.vercel.app/shipping/all`, {
        headers: getAuthHeader(),
      });
      // Count shipments by country
      const shipmentsByCountry = response.data.reduce((acc, shipping) => {
        const key = `${shipping.country} - ${shipping.province}`;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});
      setShippingDestinationsChartData({
        labels: Object.keys(shipmentsByCountry),
        datasets: [
          {
            ...shippingDestinationsChartData.datasets[0],
            data: Object.values(shipmentsByCountry),
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching shippings:", error);
    }
  };

  const fetchAllDepartments = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}departments/all`, {
        headers: getAuthHeader(),
      });
      // Use department names as labels
      const departmentNames = response.data.map((dept) => dept.name);
      const departmentDescriptions = response.data.map(
        (dept) => dept.description.length
      );
      setDepartmentsChartData({
        labels: departmentNames,
        datasets: [
          { ...departmentsChartData.datasets[0], data: departmentDescriptions },
        ],
      });
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}categories/all`, {
        headers: getAuthHeader(),
      });
      // Use category names as labels and descriptions length as data for simplicity
      const categoryNames = response.data.map((cat) => cat.name);
      const categoryDescriptions = response.data.map((cat) =>
        cat.description ? cat.description.length : 0
      );
      setCategoriesChartData({
        labels: categoryNames,
        datasets: [
          { ...categoriesChartData.datasets[0], data: categoryDescriptions },
        ],
      });
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  useEffect(() => {
    fetchAllOrders();
    fetchAllShipping();
    fetchAllDepartments();
    fetchAllCategories();
  }, []);

  return (
    <div id="barchart_container">
      <div className="chart1">
        <Bar
          data={orderRevenueChartData}
          options={{
            plugins: {
              title: { display: true, text: "Total Revenue from Orders" },
            },
          }}
        />
      </div>
      <div className="chart2">
        <Bar
          data={departmentsChartData}
          options={{
            plugins: { title: { display: true, text: "Departments" } },
          }}
        />
      </div>
      <div className="chart3">
        <Bar
          data={categoriesChartData}
          options={{
            plugins: { title: { display: true, text: "Categories" } },
          }}
        />
      </div>
    </div>
  );
};

export default AdminBarComponent;
import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../auth/AuthService";
import { useParams } from "react-router-dom";

import "../css/ProductDetails.css";
import Footer from "./Footer";

function ProductDetails() {
  const [product, setProduct] = useState({ images: [], attributes: [] });
  const [quantity, setQuantity] = useState(1);
  const { productId } = useParams();

  const API_BASE_URL = "https://trio-teach-backend-ecom.vercel.app";

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/admin/products/${productId}`
        );
        console.log(response.data); // Log the product data here
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const getAuthHeader = () => {
    const user = AuthService.getCurrentUser();
    return user ? { Authorization: `Bearer ${user.token}` } : {};
  };

  const addToCart = async () => {
    try {
      const user = AuthService.getCurrentUser();
      if (!user) {
        alert("Please log in to add items to the cart");
        return;
      }

      await axios.post(
        `${API_BASE_URL}/api/cart/add`,
        {
          productId: productId,
          quantity: quantity,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      alert("Product added to cart!");
    } catch (error) {
      console.error("Error adding product to cart:", error);
      alert("Failed to add product to cart.");
    }
  };

  return (
    <div className="product-detail-container__">
      <div className="product-detail-container">
        <h2 className="product-detail-name">{product.name}</h2>
        <p className="product-detail-price">Price: ${product.price}</p>
        <div className="product-detail-images">
          {product.images.map((image, index) => (
            <figure key={index}>
              <img
                src={image}
                alt={`Product ${index}`}
                className="product-detail-image"
                aria-label={`Product Image ${index + 1}`}
              />
            </figure>
          ))}
        </div>
        {product.description && (
          <p className="product-detail-description">
            Description: {product.description}
          </p>
        )}

        <div className="product-attributes">
          <h3>Product Attributes:</h3>
          <ul>
            {product.attributes.map((attr, index) => (
              <li key={index}>
                <strong>{attr.key}:</strong> {attr.value}
              </li>
            ))}
          </ul>
        </div>

        <div className="product-detail-quantity-adjuster">
          <button
            onClick={() => setQuantity(quantity - 1)}
            disabled={quantity <= 1}
            className="product-detail-quantity-decrease"
          >
            -
          </button>
          <span className="product-detail-quantity-display">{quantity}</span>
          <button
            onClick={() => setQuantity(quantity + 1)}
            className="product-detail-quantity-increase"
          >
            +
          </button>
        </div>
        <p className="product-detail-total-price">
          Total Price: ${(product.price * quantity).toFixed(2)}
        </p>
        <button
          onClick={addToCart}
          className="product-detail-add-to-cart-button"
        >
          Add to Cart
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default ProductDetails;
